import { ApiCreateApplicationForm } from '@@types/apiLogicTypes'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { useStore } from '@hooks/storeHook'
import { typePaymentArray } from '@modules/TicketSale/Form/TotalPrice/TotalPrice'
import { isWeekend } from '@utils/formatDates'
import { formatPhoneNumber } from '@utils/formatForm'
import { normalizePhone } from '@utils/textUtils'
import times from '@utils/times'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { DropdownChangeEvent } from 'primereact/dropdown'
import { FC, useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { IDate, IEvent } from '../../SchedulePage'
import AnimatorPart from './AnimationPart/AnimatorPart'
import ChildPart from './ChildPart'
import ClientPart from './ClientPart'
import HolidayPart from './HolidayPart'
import PaymentPart from './PaymentPart'

export interface IEventForm {
  sendFormHandler: (obj: FormData) => void
  initialState: IEvent | null
  initialDate: IDate | null
  changeEventStatus: (status: number) => void
  loadApplication: () => void
}

const EventForm: FC<IEventForm> = observer(
  ({ sendFormHandler, initialState, initialDate, changeEventStatus, loadApplication }) => {
    const {
      rooms: { rooms },
      services: { services, entertainments, shows, animations },
      holiday: { crmHolidays },
      auth: { selectedPark },
    } = useStore()

    const cheapestHoliday = crmHolidays.reduce((minHoliday, currentHoliday) => {
      return currentHoliday.weekday_price < minHoliday.weekday_price ? currentHoliday : minHoliday
    }, crmHolidays[0])

    const initialAnimation = animations.find(
      (e) => e.id === initialState?.services?.find((e) => e.category_id === 11)?.original_id
    )

    const initialEntertainments = initialState
      ? entertainments.filter((entertainment) => initialState?.services.some((e) => e.original_id === entertainment.id))
      : entertainments.filter((entertainment) => cheapestHoliday?.programs.some((e) => e.id === entertainment.id))

    const properties = initialState?.user?.properties || {}

    const propertiesArray = Object.entries(properties).map(([key]) => ({
      name: key,
      value: '1',
    }))

    const methods = useForm<ApiCreateApplicationForm>({
      mode: 'onChange',
      defaultValues: {
        order: {
          user: {
            ...initialState?.user,
            phone: initialState?.user
              ? formatPhoneNumber(initialState?.user.phone ?? '')
              : window.sessionStorage.getItem('contact_phone') ?? '',
            name: initialState?.user.name ?? window.sessionStorage.getItem('contact_name') ?? '',
            email: initialState?.user.email ?? window.sessionStorage.getItem('contact_email') ?? '',
            children: window.sessionStorage.getItem('contact_children')
              ? JSON.parse(window.sessionStorage.getItem('contact_children')!)
              : initialState?.user.children
                ? initialState?.user.children.map((e) => {
                    delete e.pivot
                    return e
                  })
                : [],
            bonus: initialState?.user?.bonus?.sum ?? 0,
            is_information_agreed: initialState ? Boolean(initialState.user?.information_agree) : false,
            is_loyalty_agreed: initialState ? Boolean(initialState.user?.loyalty_agree) : false,
            properties: propertiesArray,
          },
          holiday: initialState?.holiday
            ? crmHolidays.find((e) => e.name === initialState?.holiday.name)
            : cheapestHoliday,
          children: initialState
            ? initialState.children.map((e) => {
                delete e.pivot
                return e
              })
            : undefined,
          bonuses: initialState?.status === 4 ? initialState.payments[initialState.payments?.length - 1].bonus_sum : 0,
        },
        date: initialState?.start ? new Date(String(initialState.start)) : initialDate ? initialDate.date : new Date(),

        services: initialState
          ? shows.filter((show) => initialState?.services.some((e) => e.original_id === show.id))
          : shows.filter((show) => cheapestHoliday?.shows.some((e) => e.id === show.id)),

        animators: initialState
          ? initialState.animators.map((e) => {
              const start = e.pivot?.starts_at.substring(11, 16)
              const ends = e.pivot?.ends_at.substring(11, 16)

              return {
                entity: { ...e, pivot: undefined },
                starts_at: start,
                ends_at: ends,
              }
            })
          : undefined,
        programs: initialAnimation,
        pin_activated: initialState?.pin_activated ?? 0,
        entertainments: initialEntertainments ?? [],
        paymentType: initialState?.type === 0 ? typePaymentArray[0] : typePaymentArray[1],
        count: initialState?.holiday?.kids_count ?? 5,
        room: rooms?.find((e) => e.name === initialState?.holiday?.room?.name),
        sum:
          initialState?.status === 4
            ? initialState.payments[initialState.payments.length - 1].sum
            : initialState?.price ?? 0,
        preorder_sum: initialState?.payments?.find((e) => e.is_prepayment === 1 && e.status === 2)?.sum || 0,
      },
    })

    const isOrderPaid = initialState?.status === 4

    const [isAnimatorIncluded, setIsAnimatorIncluded] = useState(
      Boolean(
        initialState?.holiday?.name
          ? crmHolidays.find((e) => e.name === initialState.holiday.name)?.has_animator
          : cheapestHoliday.has_animator
      )
    )

    const [sum, setSum] = useState<number>(
      initialState?.price ||
        (initialDate && isWeekend(initialDate.start) ? cheapestHoliday.weekend_price : cheapestHoliday.weekday_price)
    )

    const startTimeFromInitial = initialState?.start
      ? new Date(String(initialState.start)).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        })
      : initialDate
        ? initialDate.start.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          })
        : times[0]

    const endTimeFromInitial = initialState?.start
      ? new Date(String(initialState?.end)).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        })
      : initialDate
        ? initialDate.end.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          })
        : times[1]

    const [startTime, setStartTime] = useState<string>(startTimeFromInitial)
    const [endTime, setEndTime] = useState<string>(endTimeFromInitial)

    const setStartTimeHandler = (e: DropdownChangeEvent) => {
      setStartTime(e.value)
      setEndTime('')
    }

    const setEndTimeHandler = (e: DropdownChangeEvent) => {
      setEndTime(e.value)
    }

    const onSubmit: SubmitHandler<ApiCreateApplicationForm> = async (data) => {
      const formData = new FormData()
      const starts_at = data.date.toLocaleDateString('en-CA') + ' ' + startTime + ':00'
      const ends_at = data.date.toLocaleDateString('en-CA') + ' ' + endTime + ':00'
      const holiday = crmHolidays.find((e) => e.name === data.order.holiday?.name)

      //if new order
      if (!initialState) {
        formData.append('park_id', String(selectedPark?.id))
        formData.append('holiday[id]', String(holiday?.id))
        formData.append('holiday[starts_at]', starts_at)
        formData.append('holiday[ends_at]', ends_at)
        formData.append('holiday[date]', data.date.toLocaleDateString('en-CA'))
        formData.append('holiday[time]', startTime)
        formData.append('holiday[kids_count]', String(data.count))
        formData.append('holiday[elder_count]', '2')
        formData.append('holiday[room_id]', String(data.room.id))
        if (data.order.user) {
          formData.append('holiday[user][name]', data.order.user.name)
          data.order.user.email && formData.append('holiday[user][email]', data.order.user.email)
          formData.append('holiday[user][phone]', data.order.user.phone)
        }

        let i = 0

        const excludedServices = [...data.services, ...data.entertainments].filter(
          (service) =>
            service &&
            !data.order.holiday.shows.some((e) => e.id === service.id) &&
            !data.order.holiday.programs.some((e) => e.id === service.id)
        )

        data.services.length > 0 &&
          data.services.forEach((e) => {
            formData.append(`services[${i}][id]`, String(e.id))
            if (excludedServices.some((i) => i.id === e.id))
              formData.append(
                `services[${i}][total_price]`,
                String(+e.price + (e?.base_count ? e.extra_price * Math.max(data.count - e.base_count, 0) : 0))
              )
            i++
          })

        data.entertainments.length > 0 &&
          data.entertainments.forEach((e) => {
            formData.append(`services[${i}][id]`, String(e.id))
            if (excludedServices.some((i) => i.id === e.id))
              formData.append(
                `services[${i}][total_price]`,
                String(+e.price + (e?.base_count ? e.extra_price * Math.max(data.count - e.base_count, 0) : 0))
              )
            i++
          })

        data.animators?.forEach((e, i) => {
          if (!data.animators[i].entity) return
          formData.append(`holiday[animators][${i}][id]`, String(e.entity.id))

          formData.append(
            `holiday[animators][${i}][starts_at]`,
            moment(initialDate?.start)
              .set({
                hour: parseInt(e.starts_at.split(':')[0]),
                minute: parseInt(e.starts_at.split(':')[1]),
              })
              .format('YYYY-MM-DD HH:mm:ss')
              .toString()
            // String(data.animators[0].pivot.starts_at)
          )

          formData.append(
            `holiday[animators][${i}][ends_at]`,
            moment(initialDate?.start)
              .set({
                hour: parseInt(e?.ends_at.split(':')[0]),
                minute: parseInt(e?.ends_at.split(':')[1]),
              })
              .format('YYYY-MM-DD HH:mm:ss')
              .toString()
          )
        })

        data.programs && formData.append(`services[${i}][id]`, String(data.programs.id))
        if (data.programs && !data.order.holiday.has_animator)
          formData.append(`services[${i}][total_price]`, String(data.programs.price))

        let childIndex = data.order.children?.length ?? (data.order.newKid?.length && data.order.newKid.length - 1) ?? 0

        //children from multiselect
        if (data.order.children?.length > 0) {
          data.order.children.forEach((e, i) => {
            formData.append(`holiday[kids][${i}][id]`, String(e.id))
          })
        }

        //children from inputs
        if (data.order.newKid) {
          data.order.newKid.forEach((kid) => {
            if (kid.name && kid.birth) {
              formData.append(`holiday[kids][${childIndex}][name]`, kid.name)
              formData.append(`holiday[kids][${childIndex}][birth]`, kid.birth?.toLocaleDateString('en-CA'))

              formData.append(`holiday[kids][${childIndex}][sex]`, '1')
              childIndex++
            }
          })
        }
        formData.append('price', String(data.sum != 0 ? data.sum : sum))
      } else {
        //if edit existing order
        if (
          data.order?.user &&
          !areValuesEqual(initialState.user?.phone, +normalizePhone(data.order.user.phone).substring(1))
        ) {
          formData.append('user[name]', data.order.user.name)
          data.order.user.email && formData.append('user[email]', data.order.user.email)
          formData.append('user[phone]', data.order.user.phone)
        }
        !areValuesEqual(initialState.holiday?.original_id, holiday?.id) &&
          formData.append('holiday_id', String(holiday?.id))
        !areValuesEqual(initialState.holiday?.room?.original_id, data.room.id) &&
          formData.append('room_id', String(data.room.id))
        if (
          !areValuesEqual(String(initialState.start), String(new Date(starts_at))) ||
          !areValuesEqual(String(initialState.end), String(new Date(ends_at)))
        ) {
          formData.append('starts_at', starts_at)
          formData.append('ends_at', ends_at)
        }
        !areValuesEqual(initialState.holiday?.kids_count, data.count) &&
          formData.append('kids_count', String(data.count))
        if (!areValuesEqual(initialState.price, data.sum)) formData.append('price', String(data.sum))
        else if (!areValuesEqual(initialState.price, sum)) formData.append('price', String(sum))

        console.log(data.programs)

        const deletedServices = initialState.services.filter(
          (service) =>
            !data.services.some((e) => e.id === service.original_id) &&
            !data.entertainments.some((e) => e.id === service.original_id) &&
            data.programs?.id !== service.original_id
        )

        const bothOrderServices = [...data.services, data.programs, ...data.entertainments]

        const excludedServices = bothOrderServices.filter(
          (service) =>
            service &&
            !data.order.holiday.shows.some((e) => e.id === service.original_id) &&
            !data.order.holiday.programs.some((e) => e.id === service.original_id)
        )

        const newServices = bothOrderServices.filter(
          (service) => !initialState.services.some((e) => e?.original_id === service?.id)
        )

        newServices.map((e, i) => {
          e && formData.append(`services[${i}][id]`, String(e?.id))
          e &&
            e !== data.programs &&
            excludedServices.some((el) => el.id === e.id) &&
            formData.append(
              `services[${i}][total_price]`,
              String(+e.price + (e?.base_count ? e.extra_price * Math.max(data.count - e.base_count, 0) : 0))
            )
          e &&
            e === data.programs &&
            !data.order.holiday.has_animator &&
            formData.append(`services[${i}][total_price]`, String(data.programs.price))
        })

        deletedServices.map((e, i) => formData.append(`delete_services[${i}]`, String(e.id)))

        let childIndex = data.order.children?.length

        const newKids = data.order.children.filter((kid) => !initialState.children.some((e) => e.id === kid.id))

        const deletedKids = initialState.children.filter((kid) => !data.order.children.some((e) => e.id === kid.id))

        if (newKids.length > 0 || deletedKids.length > 0 || data.order.newKid) {
          if (data.order.children?.length > 0) {
            data.order.children.forEach((e, i) => {
              formData.append(`kids[${i}][id]`, String(e.id))
            })
          }
          //children from inputs
          if (data.order.newKid) {
            data.order.newKid.forEach((kid) => {
              if (kid.name && kid.birth) {
                formData.append(`kids[${childIndex}][name]`, kid.name)
                formData.append(`kids[${childIndex}][birth]`, kid.birth?.toLocaleDateString('en-CA'))

                formData.append(`kids[${childIndex}][sex]`, '1')
                childIndex++
              }
            })
          }
        }

        if (!data.order.newKid && data.order.children.length === 0 && initialState.children.length > 0) {
          formData.append(`kids`, '0')
        }

        if (methods.getFieldState(`animators.${0}`).isDirty || methods.getFieldState(`animators.${1}`).isDirty)
          data.animators.forEach((a, i) => {
            if (!a.entity) return
            formData.append(`animators[${i}][id]`, String(a.entity.id))
            formData.append(
              `animators[${i}][starts_at]`,
              moment(initialDate?.start)
                .set({
                  hour: parseInt(a.starts_at.split(':')[0]),
                  minute: parseInt(a.starts_at.split(':')[1]),
                  seconds: parseInt('00'),
                })
                .format('YYYY-MM-DD HH:mm:ss')
                .toString()
            )
            formData.append(
              `animators[${i}][ends_at]`,
              moment(initialDate?.start)
                .set({
                  hour: parseInt(a?.ends_at.split(':')[0]),
                  minute: parseInt(a?.ends_at.split(':')[1]),
                  seconds: parseInt('00'),
                })
                .format('YYYY-MM-DD HH:mm:ss')
                .toString()
            )
          })

        if (
          !methods.getValues('animators.0.entity') &&
          !methods.getValues('animators.1.entity') &&
          initialState.animators.length > 0
        ) {
          formData.append('animators', '0')
        }
      }

      await sendFormHandler(formData)
      await loadApplication()
    }

    const calcSum = (value: ApiCreateApplicationForm) => {
      const weekdayPrice = value.order?.holiday?.weekday_price ?? 0
      const weekendPrice = value.order?.holiday?.weekend_price ?? 0
      const additionalPrice = value.order?.holiday?.weekday_additional_price ?? 0
      const dayOfWeek = value.date.getDay()
      const price =
        dayOfWeek === 0 || dayOfWeek === 6
          ? weekendPrice + additionalPrice * Math.max(value.count - value.order.holiday.count, 0)
          : weekdayPrice + additionalPrice * Math.max(value.count - value.order.holiday.count, 0)
      setSum(price)

      const services = [...value.services, ...value.entertainments].filter(
        (service) =>
          service &&
          !value.order.holiday.shows.some((e) => e.id === service.id) &&
          !value.order.holiday.programs.some((e) => e.id === service.id)
      )

      services.forEach((service) =>
        setSum(
          (prev) =>
            prev +
            +service.price +
            (service?.base_count ? service.extra_price * Math.max(value.count - service.base_count, 0) : 0)
        )
      )

      if (value.programs && !value.order.holiday.has_animator) {
        setSum((prev) => prev + +value.programs.price)
      }
    }

    const autocompleteServices = (value: ApiCreateApplicationForm) => {
      const includedShows = value.order.holiday.shows
      const includedEntertainments = value.order.holiday.programs
      methods.setValue(
        'services',
        shows.filter((e) => includedShows.some((show) => show.id === e.id))
      )
      methods.setValue(
        'entertainments',
        entertainments.filter((e) => includedEntertainments.some((ent) => ent.id === e.id))
      )
    }

    useEffect(() => {
      const subscription = methods.watch((value, { name }) => {
        if (value.order?.holiday) {
          setIsAnimatorIncluded(Boolean(value.order.holiday.has_animator))
          //@ts-ignore
          calcSum(value)
          //@ts-ignore
          name === 'order.holiday' && autocompleteServices(value)
        }
      })

      return () => subscription.unsubscribe()
    }, [methods.watch, services])

    useEffect(() => {
      return () => {
        window.sessionStorage.removeItem('event_start')
        window.sessionStorage.removeItem('event_end')
        window.sessionStorage.removeItem('event_date')

        window.sessionStorage.removeItem('contact_children')
        window.sessionStorage.removeItem('contact_name')
        window.sessionStorage.removeItem('contact_email')
        window.sessionStorage.removeItem('contact_phone')
      }
    }, [])

    return (
      <FormProvider {...methods}>
        <form className='relative h-full overflow-auto bg-white p-[1rem]' onSubmit={methods.handleSubmit(onSubmit)}>
          <div className='grid h-full grid-cols-2 gap-4'>
            <div className='flex flex-col gap-9'>
              <ClientPart
                disable={
                  !!window.sessionStorage.getItem('contact_phone') || isOrderPaid || checkInvalidStatus(initialState)
                }
              />
              <HolidayPart
                isAnimatorIncluded={isAnimatorIncluded}
                start={startTime}
                end={endTime}
                startHandler={setStartTimeHandler}
                endHandler={setEndTimeHandler}
                disabled={isOrderPaid || checkInvalidStatus(initialState)}
              />
            </div>
            <div className='flex w-full flex-col gap-9'>
              <ChildPart disable={isOrderPaid || checkInvalidStatus(initialState)} />
              <AnimatorPart disable={isOrderPaid || checkInvalidStatus(initialState)} />
              {initialState?.schedule && (
                <PaymentPart
                  sum={sum}
                  initialState={initialState}
                  changeEventStatus={changeEventStatus}
                  disabled={checkInvalidStatus(initialState)}
                  loadApplication={loadApplication}
                />
              )}
            </div>
            {!isOrderPaid && !checkInvalidStatus(initialState) && (
              <UiButton className='mt-auto w-full'>{initialState ? 'Изменить' : 'Сохранить'}</UiButton>
            )}
          </div>
        </form>
      </FormProvider>
    )
  }
)

const checkInvalidStatus = (value: IEvent | undefined | null) => {
  if (!value) return false
  return value.status === 0 || value.status === 100 || value.status === 1000
}

function areValuesEqual(value1: any, value2: any): boolean {
  if (typeof value1 !== typeof value2) {
    return false
  }

  if (typeof value1 === 'object') {
    const keys1 = Object.keys(value1)
    const keys2 = Object.keys(value2)

    if (keys1.length !== keys2.length || !keys1.every((key) => areValuesEqual(value1[key], value2[key]))) {
      return false
    }

    return true
  }

  return value1 === value2
}

export default EventForm
