import { IconElement } from '@components/ui/IconElement/IconElement'
import { UiText } from '@components/ui/UiText/UiText'
import { UserAvatar } from '@components/ui/UserAvatar/UserAvatar'
import { AppRoutesPaths, NavigationMenuItems, navigateTo } from '@config/navigation'
import { appColors } from '@config/theme'
import { useLogic, useStore } from '@hooks/storeHook'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const RootNavigation = observer(() => {
  const {
    auth,
    applications: { applications },
  } = useStore()

  const onSelect = (route: string) => {
    navigateTo(route)
  }

  const newApplications = applications.filter((e) => e.status === 1)
  const navigate = useNavigate()

  const location = useLocation()
  const logic = useLogic()

  useEffect(() => {
    logic.loadApplications()
  }, [auth.selectedPark])

  const toEditProfileHander = () => {
    navigate(AppRoutesPaths.Profile)
  }

  return (
    <div className='mr-2 min-w-[100px] max-w-[100px]'>
      <div className='fixed z-[2] flex max-h-[calc(100svh_-_32px)] min-h-[calc(100svh_-_32px)] min-w-[100px] max-w-[100px] flex-col gap-[16px] overflow-y-auto rounded-[3px] bg-white pb-[16px] pt-[12px] max-sm:min-h-screen'>
        {NavigationMenuItems.map((item) => {
          if (!item.allowedRole?.some((allowedRole) => auth.roles?.includes(allowedRole))) {
            return null
          }
          if (item.path === AppRoutesPaths.ChoiseCenter || item.path === AppRoutesPaths.Profile) {
            return null
          }
          if (item.path === AppRoutesPaths.Games && auth.selectedPark?.show_games_crm === 0) {
            return null
          }
          return (
            <div
              key={item.name}
              onClick={onSelect.bind(this, item.path)}
              style={{
                backgroundColor: location.pathname.includes(item.path) ? appColors['bg'] : appColors.white,
              }}
              className='relative flex h-[53px] w-full cursor-pointer flex-col items-center justify-center gap-[6px] hover:!bg-bg'
            >
              {item.icon && <IconElement icon={item.icon}></IconElement>}
              {item.path === AppRoutesPaths.Applications && newApplications && newApplications?.length > 0 && (
                <div
                  className={`absolute -top-1 right-4 flex h-6 w-6 items-center justify-center rounded-[50%] bg-blue`}
                >
                  <UiText size='sm' weight='bold' className='text-white'>
                    {newApplications?.length}
                  </UiText>
                </div>
              )}
              <UiText className='text-center'>{item.name}</UiText>
            </div>
          )
        })}
        <UserAvatar className='mt-auto' user={auth.user!} onClick={toEditProfileHander} />
        {
          <div
            onClick={onSelect.bind(this, AppRoutesPaths.ChoiseCenter)}
            style={{
              backgroundColor: location.pathname.includes(AppRoutesPaths.ChoiseCenter)
                ? appColors['bg']
                : appColors.white,
            }}
            className='flex h-[53px] w-full cursor-pointer flex-col items-center justify-center gap-[6px] hover:!bg-bg'
          >
            <IconElement icon={'/icons/PlaceIcon.svg'}></IconElement>
            <UiText className='text-center !text-xs'>{auth.selectedPark?.name}</UiText>
          </div>
        }
      </div>
    </div>
  )
})
